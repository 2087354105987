import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// qbi获取ticket
export const getAccessTicket = (data) => {
    return request({
        url: apiCrmHost + '/qbi/query/ticket',
        method: 'post',
        data
    });
};
