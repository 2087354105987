<template>
    <div class="ifr" v-if="isShowIframe">
        <div v-if="!iframeUrl" class="err-tips">链接错误,请联系管理员</div>
        <iframe :src="iframeUrl" frameborder="0"></iframe>
        <!-- <iframe src="https://pms.annto.com/#/mod/product/atomic-products" frameborder="0"></iframe> -->
    </div>
</template>

<script>
import { getAccessTicket } from '@mdm/api/dataManage/customerManage';
import store from '@/store';
import { Message } from 'element-ui';

export default {
    name: 'dataAnalysis',
    components: {},
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            isShowIframe: false,
            iframeUrl: '',
            isShowErrorTips: false
        };
    },
    // 监控route中的数据变化
    watch: {
        $route: {
            // eslint-disable-next-line complexity, max-lines-per-function
            handler(newVal, oldVal) {
                if (newVal.path.indexOf('-analysis') > -1) {
                    this.iframeUrl = '';
                    this.isShowErrorTips = false;
                    this.getAccessTicketInfo();
                }
            },
            immediate: true
        }
    },
    methods: {
        formParamsList(val) {
            if (!val) return {};
            const urlArr = val.split('?');
            const url = decodeURIComponent(urlArr[urlArr.length - 1]);
            const theRequest = {};
            if (url.indexOf('?') === -1) {
                const str = url.substr(url.indexOf('?') + 1);
                const strs = str.split('&');
                for (let i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
                }
            }
            return theRequest;
        },
        // 递归获取菜单权限的remark链接
        setFrontUrlList(list, url) {
            let _url = url;
            if (_url) {
                return _url;
            }
            const _uList = list;
            _uList.forEach(item => {
                if (this.$route.path === item.frontUrl) {
                    _url = item.remark;
                } else if (item.subResources) {
                    _url = this.setFrontUrlList(item.subResources, _url);
                }
            });
            return _url;
        },
        getAccessTicketInfo() {
            const _url = this.setFrontUrlList(store.getters.authority, '');
            const params = this.formParamsList(_url);
            const id = params.pageId || params.id;
            if (!id) {
                this.isShowErrorTips = true;
                return Message.error('报表链接配置有误');
            }
            getAccessTicket({ pageId: id }).then(res => {
                if (res && +res.code === 0) {
                    if (!res.data) {
                        this.isShowErrorTips = true;
                        return Message.error('无法获取正确链接,请联系管理员');
                    }
                    this.accessToken = res.data;
                    this.isShowIframe = false;
                    const timer = setTimeout(() => {
                        this.iframeUrl = _url + '&accessTicket=' + res.data;
                        this.isShowIframe = true;
                        clearTimeout(timer);
                    }, 100);
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.ifr {
    width: 100%;
    height: 100%;
    iframe {
        width: 100%;
        height: 100%;
    }
}
.err-tips {
    display: flex;
    justify-content: center;
    color: #999;
    margin-top: 20px;
    font-size: 18px;
}
</style>
